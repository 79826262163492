
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetBannerData } from 'services/AuthService'
export const getBannerApi = createAsyncThunk(
    'auth/session/getBannerApi',
    async (data) => {
        const response = await apiGetBannerData(data)
        return response
    }
)
export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        accUnqId: '',
        refresh_token:'',
        signedIn: false,
        bannerState: false,
        userCategory:"",
        bannerResponse:[],
        dashboardPermissions:[],
        dtcRowValues:[],
        ticketDetailsRowValues:[],
        dtcComments:[],
        dtcTicketStatus:[],
        ticketDetailsComments:[],
        ticketDetailsStatus:[]
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.access_token;
            state.accUnqId = action.payload.acc_unq_id;
            state.refresh_token = action.payload.refresh_token;
        },
        onFirstLogin: (state, action) => {
            state.token = action.payload.access_token;
            state.accUnqId = action.payload.acc_unq_id;
        },
       
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = '';
            state.accUnqId = '';
            state.refresh_token = '';
            state.userCategory = '';
            state.bannerState = false;
            state.bannerResponse = [];
            state.dashboardPermissions = [];
            state.dtcRowValues=[];
            state.dtcComments=[];
            state.dtcTicketStatus=[]
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setAccUnqId: (state, action) => {
            state.accUnqId = action.payload
        },
        setRefreshToken: (state, action) => {
            state.refresh_token = action.payload
        },
        setBannerState: (state, action) => {
            state.bannerState = action.payload
        },
        setDashboardPermissions: (state, action) => {
            state.dashboardPermissions = action.payload
        },
        setDtcRowValues: (state, action) => {
            state.dtcRowValues = action.payload
        },
        setTicketDetailsRowRowValues: (state, action) => {
            state.ticketDetailsRowValues = action.payload
        },
        setdtcComments: (state, action) => {
            state.dtcComments = action.payload
        },
        setdtcTicketStatus: (state, action) => {
            state.dtcTicketStatus = action.payload
        },
        setTicketDetailsComments: (state, action) => {
            state.ticketDetailsComments = action.payload
        },
        setdtcTicketDeStatus: (state, action) => {
            state.ticketDetailsStatus = action.payload
        },
        setUserCategory: (state, action) => {
            state.userCategory = action.payload
        },

    },
    extraReducers:{
        [getBannerApi.fulfilled]:(state,action) => {
            state.bannerResponse = action.payload
            state.loading = false
        },
        [getBannerApi.pending]:(state,action) => {
            state.loading = true
        },
    }
})
 
export const { onSignInSuccess, onSignOutSuccess, setToken, setAccUnqId, onFirstLogin, setRefreshToken, setSessionTime, setBannerState, setDashboardPermissions, setDtcRowValues, setdtcComments, setdtcTicketStatus, setUserCategory, setTicketDetailsRowRowValues, setTicketDetailsComments, setdtcTicketDeStatus } =
    sessionSlice.actions
 
export default sessionSlice.reducer
 