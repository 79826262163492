import React, { useEffect, useRef, useState } from 'react';
import { IoPinOutline, IoPin, IoEyeOffOutline, IoArrowUpOutline, IoArrowDownOutline, IoOptionsOutline } from 'react-icons/io5';
import { LuPin, LuPinOff } from 'react-icons/lu';
import { SlEqualizer } from 'react-icons/sl';
const ColumnMenu = ({
  onSort,
  onPinLeft,
  onPinRight,
  onHide,
  onManage,
  isPinned,
  isHidden,
  onClose,
  columns,
  visibleColumns,
  manageColumns,
  onApplyColumns
}) => {
  const menuRef = useRef();
  const [checkedColumns, setCheckedColumns] = useState({});
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    setFilteredColumns(columns.filter(col => col.id.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm, columns]);

  useEffect(() => {
    const initialCheckedState = {};
    columns.forEach(col => {
      initialCheckedState[col.id] = visibleColumns.some(visibleCol => visibleCol.id === col.id);
    });
    setCheckedColumns(initialCheckedState);

  }, [columns, visibleColumns, onHide]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleCheckboxChange = (columnId) => {
    setCheckedColumns((prevChecked) => ({
      ...prevChecked,
      [columnId]: !prevChecked[columnId],
    }));
  };

  const handleApplyColumns = () => {
    const updatedSelected = columns
      .filter(col => checkedColumns[col.id])
      .map(col => col.id);

    onApplyColumns(updatedSelected);
    onClose();
  };

  const handleSelectAll = () => {
    const updatedChecked = {};
    columns.forEach(col => {
      updatedChecked[col.id] = !selectAll;
    });
    setCheckedColumns(updatedChecked);
    setSelectAll(!selectAll);
  };

  const handleReset = () => {
    const resetCheckedState = {};
    columns.forEach(col => {
      resetCheckedState[col.id] = visibleColumns.some(visibleCol => visibleCol.id === col.id);
    });
    setCheckedColumns(resetCheckedState);
    setSelectAll(false);
  };
  return (
    <div ref={menuRef} className="absolute bg-white shadow-lg rounded-md z-10 mt-6 overflow-y-auto" style={{ maxHeight: '260px', minWidth:'250px' }}>
      {manageColumns ? (
        <>
          <input
            type="text"
            placeholder="Search.."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded p-2 w-full"
          />
          {filteredColumns.map((item) => (
            <ul className="flex m-2 p-2" key={item.id}>
              <input
                type="checkbox"
                className="mx-2"
                checked={checkedColumns[item.id] || false}
                onChange={() => handleCheckboxChange(item.id)}
              />
              <li>{item?.id}</li>
            </ul>
          ))}

          <div className="flex justify-between mx-2">
            <button
              className="text-blue-600 underline"
              onClick={handleSelectAll}
            >
              {selectAll ? 'Deselect All' : 'Select All'}
            </button>
            <button
              className="text-red-600 underline mr-4"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <p className="m-2 p-2 cursor-pointer" onClick={handleApplyColumns}>
            Apply Manage Columns
          </p>
        </>
      ) : (
        <ul className="list-none p-2">
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={() => onSort('asc')}>
            <IoArrowUpOutline className="inline mr-2" size={20} /> Sort by ASC
          </li>
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={() => onSort('desc')}>
            <IoArrowDownOutline className="inline mr-2" size={20}/> Sort by DESC
          </li>
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={() => onPinLeft('left')}>
            {isPinned === 'left' ? <LuPinOff className="inline mr-2" size={20}/> : <LuPin className="inline mr-2" size={20}/>}
            {isPinned === 'left' ? ' Unpin from left' : ' Pin to left'}
          </li>
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={() => onPinRight('right')}>
            {isPinned === 'right' ? <LuPinOff className="inline mr-2" size={20}/> : <LuPin className="inline mr-2" size={20}/>}
            {isPinned === 'right' ? ' Unpin from right' : ' Pin to right'}
          </li>
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={onHide}>
            <IoEyeOffOutline className="inline mr-2" size={20}/> Hide column
             
          </li>
          <li className="text-base cursor-pointer p-2 hover:bg-gray-200 text-gray-500 font-light" style={{ textTransform: 'none' }} onClick={onManage}>
            <SlEqualizer className="inline mr-2" size={20}/> Manage column
          </li>
        </ul>
      )}
    </div>
  );
};
export default ColumnMenu;
