export function isSameMonth(date, comparison) {
    if (!(date instanceof Date) || isNaN(date)) {
        console.error('Invalid date provided:', date);
        return false;
    }
    
    if (!(comparison instanceof Date) || isNaN(comparison)) {
        console.error('Invalid comparison date provided:', comparison);
        return false;
    }
    
    return (
        date.getFullYear() === comparison.getFullYear() &&
        date.getMonth() === comparison.getMonth()
    );
}
