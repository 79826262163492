import React, { useRef, useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import cloneDeep from 'lodash/cloneDeep'
import FileItem from './FileItem'
import Button from '../Buttons'
import CloseButton from '../CloseButton'
import Notification from '../Notification'
import toast from '../toast'
import { RiDeleteBinLine } from 'react-icons/ri'
import { HiOutlineTrash } from 'react-icons/hi2'
import { GrDocumentCsv } from 'react-icons/gr'

const filesToArray = (files) => Object.keys(files).map((key) => files[key])

const Upload = React.forwardRef((props, ref) => {
    const {
        accept,
        beforeUpload,
        disabled,
        draggable,
        fileList,
        multiple,
        onChange,
        onFileRemove,
        showList,
        tip,
        uploadLimit,
        children,
        className,
        field,
        form,
        name,
        ...rest
    } = props

    const fileInputField = useRef(null)
    const [files, setFiles] = useState(fileList)
    const [dragOver, setDragOver] = useState(false)

    const { themeColor, primaryColorLevel } = useConfig()

    useEffect(() => {
        setFiles(fileList)
    }, [fileList])

    const triggerMessage = (msg) => {
        toast.push(
            <Notification type={msg ? "success" : "danger"} duration={2000}>
                {msg || 'Upload Failed!'}
            </Notification>,
            {
                placement: 'top-center',
            }
        )
    }

    const pushFile = (newFiles, file) => {
        for (let f of newFiles) {
            file.push(f)
        }
        return file
    }

    const addNewFiles = (newFiles) => {
        let file = cloneDeep(files)
        if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
            if (Object.keys(file).length >= uploadLimit) {
                if (uploadLimit === 1) {
                    file.shift()
                    file = pushFile(newFiles, file)
                }

                return filesToArray({ ...file })
            }
        }
        file = pushFile(newFiles, file)
        return filesToArray({ ...file })
    }

    const onNewFileUpload = async (e) => {
        const filesdetails = e.target.files[0]
        console.log(filesdetails);
        const { files: newFiles } = e.target
        let result = true

        if (beforeUpload) {
            result = await beforeUpload(newFiles, files)

            if (result === false) {
                //triggerMessage()
                // return
            }

            if (typeof result === 'string' && result.length > 0) {
                triggerMessage(result)
                // return
            }
            if (result?.message && result?.success) {
                triggerMessage(result?.message)
            }
        }
        if (result) {
            console.log("props", props);
            let updatedFiles = addNewFiles(newFiles)
            setFiles(updatedFiles);
            onChange(updatedFiles, files, result, filesdetails)
        }
    }

    // const removeFile = (fileIndex) => {
    //     const deletedFileList = files.filter((_, index) => index !== fileIndex)
    //     setFiles([deletedFileList])
    //     onFileRemove?.(deletedFileList)
    // }

    const removeFile = (fileIndex) => {
        const deletedFileList = files.filter((_, index) => index !== fileIndex)
        setFiles([])
        onFileRemove?.([])
    }

    const triggerUpload = (e) => {
        if (!disabled) {
            fileInputField.current?.click()
        }
        e.stopPropagation()
    }

    const renderChildren = () => {
        if (!draggable && !children) {
            return (
                <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
                    Upload
                </Button>
            )
        }

        if (draggable && !children) {
            return <div className='p-2'><p className='flex items-center pt-4'><span className="p-2" style={{margin:"auto",backgroundColor:"#F0FDF4"}}><GrDocumentCsv size={30} style={{color:"#15803D"}}/></span></p><p className='pt-2'><span><span style={{color:"#111827",fontWeight:"600"}}>Drop your file here or</span> <span style={{color:"#2563EB",fontWeight:"600"}}>Browse</span></span></p> <p className="p-2" style={{color:"#9CA3AF",textAlign:"center"}}>Support: csv</p></div>
        }

        return children
    }

    const handleDragLeave = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const handleDragOver = useCallback(() => {
        if (draggable && !disabled) {
            setDragOver(true)
        }
    }, [draggable, disabled])

    const handleDrop = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const draggableProp = {
        onDragLeave: handleDragLeave,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
    }

    const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`

    const uploadClass = classNames(
        'upload',
        draggable && `upload-draggable`,
        draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
        draggable && disabled && 'disabled',
        dragOver && draggableEventFeedbackClass,
        className
    )

    const uploadInputClass = classNames(
        'upload-input',
        draggable && `draggable`
    )

    return (
        <>
            <div
                ref={ref}
                className={uploadClass}
                {...(draggable ? draggableProp : { onClick: triggerUpload })}
                {...rest}
            >
                <input
                    className={uploadInputClass}
                    type="file"
                    ref={fileInputField}
                    onChange={onNewFileUpload}
                    disabled={disabled}
                    multiple={multiple}
                    accept={accept}
                    title=""
                    value=""
                    {...field}
                    {...rest}
                    name={name}
                ></input>
                {renderChildren()}
            </div>
            {tip}
            {showList && (
                <>
                    {files.length > 0 && (
                        <div className="upload-file-list border rounded-md">
                            {files.map((file, index) => (
                                <>
                                    {(files?.length > 1 && files?.length - 1 == index) &&
                                        <div className='flex justify-between'>
                                            <FileItem file={file} key={file.name + index}>
                                            </FileItem>
                                            <div className='p-2 text-red-600 ' onClick={() => removeFile(index)}>
                                                <HiOutlineTrash size={20}/>
                                            </div>
                                        </div>
                                    }

                                    {(files?.length === 1 && files?.length - 1 == index) &&
                                        <div className='flex justify-between'>
                                            <FileItem file={file} key={file.name + index}>
                                            </FileItem>
                                            <div className='p-2 text-red-600 ' onClick={() => removeFile(index)}>
                                                <HiOutlineTrash size={20}/>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    )

                    }

                </>
            )}
        </>
    )
})

Upload.propTypes = {
    uploadLimit: PropTypes.number,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    showList: PropTypes.bool,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Upload.defaultProps = {
    draggable: false,
    showList: true,
    disabled: false,
    fileList: [],
}

export default Upload
