import React from 'react'
import { GrDocumentCsv } from 'react-icons/gr'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const { type, name, size } = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
               <GrDocumentCsv size={25} style={{color:"#15803D"}}/>
            </FileIcon>
        )
    }

    return (
        <div className=" w-4/5">
            <div className="flex">
                <div className="upload-file-thumbnail"><span className='p-2' style={{backgroundColor:"#F0FDF4"}}>{renderThumbnail()}</span></div>
                <div className="upload-file-info w-4/5">
                    <p className="upload-file-name font-semibold w-4/5">{name}</p>
                    <span className="upload-file-size">{getKB(size) || 1} kb</span>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
