const appConfig = {
    // apiPrefix: 'http://10.223.72.180:8765/api',
    // apiPrefix: 'http://3.109.42.45:8765/api/v1',
    
    // apiPrefix: 'http://115.248.56.28:8765/api/v1',
    
  //  apiPrefix:'http://10.235.245.217:9876/api/v1',            /*dev server */
    
    //  apiPrefix:'https://uptimecenter.airlinq.com/api/v1',
    
    // apiPrefixRedmine:'https://uptimecenter.airlinq.com/my/page',
        //apiPrefixRedmine:'https://uat.rakshana.bharatbenz.com/mine',
        apiPrefix:'https://uat.rakshana.bharatbenz.com/api/v1',              /*Qa servere */
        //apiPrefixRedmine:'http://localhost/mine',
        //apiPrefix:'http://localhost/api/v1',

        truckLangChainApiPrefix: 'https://chat.airlinq.ai/app1/*',

    // apiPrefix:'http://192.168.0.205:9876/api/v1',           


    // apiPrefix: 'http://192.168.0.104:9876/api/v1',


    mockApiPrefix: '/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    dateFormat : "DD-MM-YYYY"
}

export default appConfig
