
import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { jwtDecode } from 'jwt-decode';
import { getBannerApi, onSignOutSuccess, setBannerState, setRefreshToken, setToken } from 'store/auth/sessionSlice'
import { bannerApi, refreshTokenApi } from 'services/AuthService'
 
const environment = process.env.NODE_ENV
 
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}
 
function App() {
    const { token, refresh_token, signedIn } = useSelector((state) => state?.auth?.session);
    const [isValidToken, setIsValidToken] = useState(true);
    const [timeDifferenceMinutes, setTimeDifferenceMinutes] = useState("");
    const dispatch = useDispatch();
    
    const end_time = useSelector((state)=>state?.auth?.session?.bannerResponse?.data?.data[0]?.end_time)
    const start_time = useSelector((state)=>state?.auth?.session?.bannerResponse?.data?.data[0]?.start_time)
    let startTime = new Date(start_time);
    let endTime = new Date(end_time);
    startTime.setHours(startTime.getHours() - 5);
    startTime.setMinutes(startTime.getMinutes() - 30);
    endTime.setHours(endTime.getHours() - 5);
    endTime.setMinutes(endTime.getMinutes() - 30);
    const bannerState = useSelector((state)=>state?.auth?.session?.bannerState)
    useEffect(()=>{
        if(signedIn){
            const bannerResponse = setInterval(()=>{
                let currentTime = new Date();
                // dispatch(getBannerApi())
                if(startTime.getTime() <= currentTime.getTime() && currentTime.getTime()<endTime.getTime()){
                   
                    dispatch(setBannerState(true))
                }else if(endTime.getTime() <= currentTime.getTime()){
                    dispatch(setBannerState(false))
     
                }else if(startTime.getTime() > currentTime.getTime() || currentTime.getTime()<endTime.getTime()){
                  
     
                    dispatch(setBannerState(false))
                }
            }, 10000)
           
            return () => clearInterval(bannerResponse);
        }
    },[bannerState,startTime,endTime])


    useEffect(() => {
        const checkJwtExpire = () => {
            if (!token) {
                setIsValidToken(false);
                return;
            }
            const decodedToken = jwtDecode(token);
            if (!decodedToken?.exp) {
                setIsValidToken(false);
                return;
            }
            const currentTime = Date.now() / 1000;
            const expiryTime = decodedToken.exp;
            const timeDifferenceSeconds = expiryTime - currentTime;
            const newTimeDifferenceMinutes = Math.floor(timeDifferenceSeconds / 60);
            setTimeDifferenceMinutes(newTimeDifferenceMinutes);
            setIsValidToken(expiryTime >= currentTime);
        };
       
        checkJwtExpire();
       
        const intervalId = setInterval(checkJwtExpire, 60 * 1000);
       
        return () => clearInterval(intervalId);
    }, [token]);
   
    const createPayload = {refresh_token: refresh_token}
 
    useEffect(()=>{
        if(timeDifferenceMinutes && timeDifferenceMinutes<10){
            refreshTokenApi(createPayload)
            .then(resp => {
                dispatch(setRefreshToken(resp?.data?.refresh_token))
                dispatch(setToken(resp?.data?.access_token))
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    },[timeDifferenceMinutes])
   
    useEffect(()=>{
        if(!isValidToken){
            dispatch(onSignOutSuccess())
        }
    },[isValidToken])
    return (
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <Theme>
                        <Layout />
                    </Theme>
                </BrowserRouter>
            </PersistGate>
    );
}
 
export default App;
 