
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './chatstyles.css'
import './machinebook.css'
import './expenses.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import "@fontsource/league-gothic"; // Defaults to weight 400
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
 
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    // <React.StrictMode>
    <ChakraProvider>
        <Provider store={store}>
        <App />
        </Provider>
    </ChakraProvider>
    // </React.StrictMode>
)
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
 
