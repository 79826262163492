import BaseService from './BaseService'
//dinesh test
const ApiService = {
    fetchData(param,onUploadProgress) {
        return new Promise((resolve, reject) => {
            BaseService.interceptors.request.use(
                (config) => {
                  if (onUploadProgress) {
                    config.onUploadProgress = onUploadProgress;
                  }
                  return config;
                },
                (error) => {
                  return Promise.reject(error);
                }
              );
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
