export const APP_NAME = 'Daimler Inc.'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'
 
export const APP_LOGO = require('../assets/Logo_Large.png');
 
 
// lower the delay faster the typing speed
export const TYPING_DELAY = 7
// languages supported
// https://github.com/JamesBrill/react-speech-recognition/blob/master/docs/API.md#language-string
export const VOICE_SEARCH_LANGUAGE = 'en-IN'
 
// for voices visit https://codesandbox.io/s/text-to-speech-voices-pitch-rate-021nib
export const VOICE=4
 
// max-2, min-0.5
export const PITCH=1
 
// max-2, min-0.5
export const RATE=1
 
// max-1, min-0
export const VOLUME=1
 
// 'true' to enable text to speech
export const IS_TEXT_TO_SPEECH=true