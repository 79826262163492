import { Tooltip } from 'components/ui'
import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'

const NavToggle = ({ toggled, className }) => {
    return (
        // <Tooltip title={toggled ? "Expand Menu":"Collapse Menu"} placement='bottom'>
         <div className={className}>
            {toggled ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 />}
        </div>
        // </Tooltip>
    )
}

export default NavToggle
