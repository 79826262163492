import React, {
    forwardRef,
    useMemo,
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
    Table,
    Pagination,
    Select,
    Checkbox,
    Alert,
    Button,
} from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import { IoCloseOutline } from 'react-icons/io5'
import ColumnMenu from './ColumnsMenu'
import { SlOptionsVertical } from "react-icons/sl";
import Loading from './Loading'
import { GrFormSearch } from "react-icons/gr";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import { useDispatch, useSelector } from 'react-redux'
import { BiSort } from "react-icons/bi";
import { LuMoveUp } from "react-icons/lu";
import { LuMoveDown } from "react-icons/lu";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { RiExpandUpDownLine } from 'react-icons/ri'
import { Heading } from '@chakra-ui/react'


const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = (props) => {
    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        ...rest
    } = props

    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])



    const handleChange = (e) => {
        onChange(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return (
        <Checkbox
            className="mb-0 bg-white rounded shadow border-rounded"
            style={{ border: "2px solid grey" }}
            ref={ref}
            onChange={(_, e) => handleChange(e)}
            {...rest}
        />
    )
}

// const Sorterss = ({ sort }) => {
//     if (sort === 'asc') {
//         return <span className='mt-1'><LuMoveUp/></span>; // Ascending icon
//     } else if (sort === 'desc') {
//         return <span className='mt-1'><LuMoveDown /></span>; // Descending icon
//     } else {
//         return <span className='mt-1'><BiSort/></span>; // No sorting icon
//     }
// };

const Sorterss = ({ sort }) => {
    if (sort === 'asc') {
        return <span className='mt-1 text-gray-500'><MdOutlineKeyboardArrowUp /></span>; // Ascending icon
    } else if (sort === 'desc') {
        return <span className='mt-1 text-gray-500'><MdOutlineKeyboardArrowDown /></span>; // Descending icon
    } else {
        return <span className='mt-1 text-gray-500'><RiExpandUpDownLine /></span>; // No sorting icon
    }
};


const DataTable = forwardRef((props, ref) => {
    const {
        skeletonAvatarColumns,
        columns: columnsProp,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
        filterDisplay,
        columnVisiblityTable,
        closeFilter,
        searchFilter,
        onPin,
        onHide,
        onManage,
    } = props

    const { pageSize, pageIndex, total } = pagingData
    const [sorting, setSorting] = useState(null)
    const [columnvisiblity, setcolumnvisiblity] = useState({})
    const [menuColumnId, setMenuColumnId] = useState(null)
    const [manageColumns, setManageColumns] = useState(false)
    const [allColumnsForManagement, setAllColumnsForManagement] = useState(columnsProp);
    const dispatch = useDispatch()

    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number}/page`,
            })),
        [pageSizes]
    )
    const [columnVisibility, setColumnVisibility] = useState({});

    const handleHideColumn = (columnId) => {
        const filteredColumns = visibleColumns.filter((col) => col.id !== columnId);
        setVisibleColumns(filteredColumns);
        setAllColumnsForManagement(filteredColumns);
    };
    const handleManageColumns = () => {
        setManageColumns(true);
        setAllColumnsForManagement(visibleColumns);
    };
    const handleCheckBoxChange = (checked, row) => {
        // if (!loading) {
        onCheckBoxChange?.(checked, row)
        // }
    }
    const handleSearchFilter = (id) => {
        searchFilter(id)

    }

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        // if (!loading) {
        onIndeterminateCheckBoxChange?.(checked, rows)
        // }
    }

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value))
        }
    }
    const handleSort = (id, direction) => {
        setSorting([{ id, desc: direction === 'desc' }])
    }
    const [pinnedColumns, setPinnedColumns] = useState([]);

    const handlePinColumn = (column, direction) => {
        setManageColumns(false)
        setMenuColumnId(null)
        if (column) {
            const currentPin = column.getIsPinned();
            if (direction === 'left') {
                column.pin(currentPin === 'left' ? false : 'left');
            } else if (direction === 'right') {
                column.pin(currentPin === 'right' ? false : 'right');
            } else {
                column.pin(false); // Unpin
            }
        }
        setPinnedColumns((prevPinnedColumns) => {
            const isAlreadyPinned = prevPinnedColumns.some(pinned => pinned.id === column.id);
            if (isAlreadyPinned) {
                return prevPinnedColumns.filter(pinned => pinned.id !== column.id);
            }
            return [
                ...prevPinnedColumns,
                { id: column.id, width: column.getSize() },
            ];
        });
    };

    const getPinnedColumnLeft = (columnId) => {
        let left = 0;
        pinnedColumns
            .filter(pinned => pinned.direction === 'left') // Only sum left-pinned columns
            .forEach((pinned) => {
                if (pinned.id === columnId) return; // Stop when the pinned column is found
                left += pinned.width;
            });
        return left;
    };

    const getPinnedColumnRight = (columnId) => {
        let right = 0;
        for (let pinned of [...pinnedColumns].reverse()) {
            if (pinned.id === columnId) break;
            right += pinned.width;
        }
        return right;
    };

    // const transformedData = useMemo(() => {
    //     return data.map(row => {
    //         const transformedRow = {};
    //         Object.keys(row).forEach(key => {
    //             transformedRow[key] = String(row[key]);
    //         });
    //         return transformedRow;
    //     });
    // }, [data]);
    // const filter=useSelector(state=>state.myaccountList.data.filterDisplay)

    // const filterClose=()=>{
    //     dispatch(setUserFilterDisplay(!filterDisplay));
    //     dispatch(setOperatorFilterDisplay(!filterDisplay));
    //     dispatch(setProviderFilterDisplay(!filterDisplay));
    //     dispatch(setPartnerFilterDisplay(!filterDisplay));
    //     dispatch(setPublicRoleFilterDisplay(!filterDisplay));
    // }

    // useEffect(() => {
    //     if (Array.isArray(sorting)) {
    //         const sortOrder =
    //             sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''
    //         const id = sorting.length > 0 ? sorting[0].id : ''
    //         onSort?.({ order: sortOrder, key: id })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sorting])





    const hasOldColumnMetaKey = columnsProp.some(
        (col) => col.Header || col.accessor || col.Cell
    )
    const [visibleColumns, setVisibleColumns] = useState(columnsProp);

    const handleApplyColumns = (selectedColumnIds) => {
        const filteredColumns = columnsProp.filter((col) => selectedColumnIds.includes(col.id));
        setVisibleColumns(filteredColumns);
    };
    const finalColumns = useMemo(() => {
        const columns = visibleColumns

        if (selectable) {
            return [
                {
                    id: 'select',
                    size: "30",
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            onIndeterminateCheckBoxChange={(e) => {
                                handleIndeterminateCheckBoxChange(
                                    e.target.checked,
                                    table.getRowModel().rows
                                )
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            onCheckBoxChange={(e) =>
                                handleCheckBoxChange(
                                    e.target.checked,
                                    row.original
                                )
                            }
                        />
                    ),
                    enableColumnFilter: false,
                },
                ...columns,
            ]
        }
        return columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleColumns, selectable])
    const isDataEmpty = data.length === 0;

    const table = useReactTable({
        data,
        columns: hasOldColumnMetaKey ? [] : finalColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,

        onSortingChange: setSorting,
        onColumnVisibilityChange: setcolumnvisiblity,
        state: {
            sorting,
            columnVisibility, // Pass the updated column visibility state to the table
        },
    });

    const resetSorting = () => {
        table.resetSorting()
    }

    const resetSelected = () => {
        table.toggleAllRowsSelected(false)
    }

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected,
    }))

    if (hasOldColumnMetaKey) {
        const message =
            'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8'

        if (process.env.NODE_ENV === 'development') {
            console.warn(message)
        }

        return <Alert>{message}</Alert>
    }

    return (
        <div className="flex h-full">
            <Loading
                loading={loading && data.length !== 0}
                type="cover"
                className="overflow-x-auto  w-full "
            >
                <Table className="relative  overflow-y-auto">

                    <THead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            !loading && <Tr key={headerGroup.id} class="bg-gray-100" style={{height:"30px"}}>
                                {headerGroup.headers.map((header) => {
                                    const isPinned = pinnedColumns.some(
                                        (pinned) => pinned.id === header.id
                                    );
                                    const pinnedLeft = isPinned
                                        ? getPinnedColumnLeft(header.id)
                                        : undefined;
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                position: header.column.getIsPinned() ? 'sticky' : 'relative',
                                                left: header.column.getIsPinned() === 'left' ? getPinnedColumnLeft(header.id) : undefined,
                                                right: header.column.getIsPinned() === 'right' ? getPinnedColumnRight(header.id) : undefined,
                                                zIndex: header.column.getIsPinned() ? 10 : 'auto',
                                                padding: ".5rem 1.5rem",
                                            }}
                                        >
                                            {header.isPlaceholder &&
                                                header.column.columnDef
                                                    .enableHidding ? null : (
                                                <div>
                                                    <div className='flex' style={{ width: `${header.column.columnDef.size}px` }}>
                                                        <div
                                                            className={classNames(
                                                                header.column.getCanSort() &&
                                                                'cursor-pointer select-none point font-bold capitalize flex font-bold text-sm',
                                                                loading &&
                                                                'pointer-events-none font-bold font-bold'
                                                            )}
                                                            style={{ textTransform: 'none' }}
                                                        // onClick={header.column.getToggleSortingHandler()}
                                                        >
                                                            {/* {header.column.getCanSort() && (
                                                                <Sorterss
                                                                    sort={header.column.getIsSorted()}
                                                                />
                                                            )} */}
                                                            {flexRender(
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext()
                                                            )}

                                                        </div>
                                                        <div className='flex'>
                                                            {header.column.columnDef.enableSearchFilter && (
                                                                <GrFormSearch className='text-xl' onClick={() => handleSearchFilter(header.column.id)} />
                                                            )}
                                                            {header.column.columnDef.enableMultiOption && (
                                                                <SlOptionsVertical className="cursor-pointer mt-1" onClick={() => setMenuColumnId(header.id)} />
                                                            )}
                                                            {menuColumnId === header.id && (
                                                                <ColumnMenu
                                                                    onSort={(direction) => handleSort(header.id, direction)}
                                                                    onPinLeft={() => handlePinColumn(header.column, 'left')}
                                                                    onPinRight={() => handlePinColumn(header.column, 'right')}
                                                                    onHide={() => handleHideColumn(header.id)}
                                                                    onManage={handleManageColumns}
                                                                    isPinned={header.column.getIsPinned()}
                                                                    isHidden={header.hidden}
                                                                    onApplyColumns={handleApplyColumns}
                                                                    onClose={() => {
                                                                        setManageColumns(false)
                                                                        setMenuColumnId(null)
                                                                    }
                                                                    }
                                                                    columns={columnsProp}
                                                                    visibleColumns={visibleColumns}
                                                                    manageColumns={manageColumns}
                                                                />
                                                            )}


                                                        </div>


                                                    </div>
                                                    {header.column.getCanFilter() && (
                                                        <div>
                                                            {header.column.id.toLowerCase() === 'status' ? (<select
                                                                value={header.column.getFilterValue() || ''}
                                                                onChange={(e) => {

                                                                    header.column.setFilterValue(e.target.value)
                                                                }}
                                                                className="border rounded p-1 w-full"
                                                            >
                                                                <option value="">All</option>
                                                                <option value="ACTIVE">Active</option>
                                                                <option value="IN_ACTIVE">Inactive</option>
                                                            </select>) : header.column.id.toLowerCase() === 'segment' ? (
                                                                <select
                                                                    value={header.column.getFilterValue() || ''}
                                                                    onChange={(e) => {

                                                                        header.column.setFilterValue(e.target.value)
                                                                    }}
                                                                    className="border rounded p-1 w-full"
                                                                >
                                                                    <option value="">All</option>
                                                                    <option value="MDT R">MDT R</option>
                                                                    <option value="HDT C">HDT C</option>
                                                                    <option value="HDT R">HDT R</option>
                                                                </select>
                                                            ) : (
                                                                <input
                                                                    type="search"
                                                                    value={header.column.getFilterValue() || ''}
                                                                    onChange={(e) => header.column.setFilterValue(e.target.value)}
                                                                    placeholder="search"
                                                                    className="border rounded p-1 w-full"
                                                                />)}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </THead>

                    {loading || data.length === 0 ? (
                        <TableRowSkeleton
                            columns={finalColumns.length}
                            rows={pagingData.pageSize}
                            avatarInColumns={skeletonAvatarColumns}
                            avatarProps={skeletonAvatarProps}
                        />
                    ) : (
                        <TBody >
                            {table
                                .getRowModel()
                                .rows.slice(0, pageSize)
                                .map((row) => {
                                    return (
                                        <Tr key={row.id} style={{ height: "40px" }} className="hover:bg-gray-100">
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {

                                                    const isPinned = pinnedColumns.some(
                                                        (pinned) =>
                                                            pinned.id === cell.column.id
                                                    );
                                                    const pinnedLeft = isPinned
                                                        ? getPinnedColumnLeft(cell.column.id)
                                                        : undefined;
                                                    const pinnedRight = isPinned
                                                        ? getPinnedColumnRight(cell.column.id)
                                                        : undefined;
                                                    return (
                                                        <Td key={cell.id}
                                                            style={{
                                                                position: cell.column.getIsPinned() ? 'sticky' : 'relative',
                                                                left: cell.column.getIsPinned() === 'left' ? getPinnedColumnLeft(cell.column.id) : undefined,
                                                                right: cell.column.getIsPinned() === 'right' ? getPinnedColumnRight(cell.column.id) : undefined,
                                                                //background: 'white',
                                                                color: "#4B5563",
                                                                zIndex: cell.column.getIsPinned() ? 5 : 'auto',
                                                            }}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </Td>
                                                    )
                                                })}
                                        </Tr>
                                    )
                                })}
                        </TBody>
                    )}
                </Table>
                {/* <div className='relative border'>
                    <table className='max-h-64 overflow-y-auto'>
 
 
                    </table>
 
                </div> */}


                <div className="flex items-center justify-between mt-1 border p-2 rounded">
                    <div className="flex space-x-4">
                        <div className="mr-0 m-2 flex items-center"><p style={{color:"#6B7280",fontWeight:"600"}}>Total {total} items</p></div>
                        <Pagination
                            pageSize={pageSize}
                            currentPage={pageIndex}
                            total={total}
                            onChange={handlePaginationChange}
                        />
                    </div>
                    {!isDataEmpty && (<Select
                        size="sm"
                        menuPlacement="top"
                        isSearchable={false}
                        value={pageSizeOption.filter(
                            (option) => option.value === pageSize
                        )}
                        options={pageSizeOption}
                        onChange={(option) =>
                            handleSelectChange(option.value)
                        }
                    />)}
                </div>

            </Loading>
            {/* {filterDisplay && (
                <div className="border-l p-3 w-72">
                    <div className="flex justify-between pb-2">
                        <p className="text-lg ">Filter</p>
                        <IoCloseOutline
                            className="text-2xl mt-1"
                            onClick={closeFilter}
                        />
                    </div>
                    <hr />
 
                    <div className="my-4">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <div key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <div key={header.id}>
                                            {header.isPlaceholder ? null : (
                                                <div className="space-y-2 ">
                                                    {header.column.getCanFilter() && (
                                                        <p className="text-lg">
                                                            {
                                                                header.column
                                                                    .columnDef
                                                                    .header
                                                            }
                                                        </p>
                                                    )}
                                                    {header.column.getCanFilter() ? (
                                                        <div className="space-y-2 w-full">
                                                            {header.column.getCanFilter() ? (
                                                                <div className="space-y-2 w-full">
                                                                    {header.column.id.toLowerCase() === 'status' ? (
                                                                        <select
                                                                            value={header.column.getFilterValue() || ''}
                                                                            onChange={(e) => {
                                                                                console.log('Selected Status:', e.target.value);
                                                                                header.column.setFilterValue(e.target.value)
                                                                            }}
                                                                            className="border rounded p-3 w-full"
                                                                        >
                                                                            <option value="ACTIVE">Active</option>
                                                                            <option value="IN_ACTIVE">Inactive</option>
                                                                        </select>
                                                                    ) : (
                                                                        <input
                                                                            type="text"
                                                                            value={header.column.getFilterValue() || ''}
                                                                            onChange={(e) => header.column.setFilterValue(e.target.value)}
                                                                            placeholder="search"
                                                                            className="border rounded p-2 w-full"
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : null}
 
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                        <Button
                            variant="solid"
                            className="mt-2"
                            onClick={() => {
                                table.resetColumnFilters()
                            }}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )} */}
        </div>
    )
})

DataTable.propTypes = {
    onPin: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onManage: PropTypes.func.isRequired,
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
}


DataTable.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
}

export default DataTable
